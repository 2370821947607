import { defineStore } from "pinia";
import {
  OrderMinimumManage,
  OrderMinimum,
} from "~/types/settings-order-minimum";
import { ref } from "vue";

export const useSettingsOrderMinimumStore = defineStore(
  "settings-order-minimum",
  () => {
    // Order Minimum Rules
    const orderMinimumRules = ref<OrderMinimum[]>([]);

    async function getOrderMinimumRules() {
      let response: any;
      try {
        response = await useVaniloApi("/order-minimums");
      } catch (error) {
        useErrorNotifications(error);
      }
      orderMinimumRules.value = response?.data;
      return response;
    }

    async function deleteOrderMinimumRule(id: number) {
      let response: any;
      try {
        response = await useVaniloApi(`/order-minimums/${id}`, {
          method: "DELETE",
        });
      } catch (error) {
        useErrorNotifications(error);
      }
      return response;
    }

    // Order Minimum Rules Store
    async function getOrderMinimumRulesStore(id: number) {
      let response: any;
      try {
        response = await useVaniloApi(`/order-minimums/store/${id}`);
      } catch (error) {
        useErrorNotifications(error);
      }
      return response;
    }

    // Single Order Minimum Rule
    const editingOrderMinimumRule = ref<OrderMinimumManage>(
      {} as OrderMinimumManage
    );
    const viewingOrderMinimumRule = ref<OrderMinimum>({} as OrderMinimum);

    async function getSingleOrderMinimumRule(id: number) {
      let response: any;
      try {
        response = await useVaniloApi(`/order-minimums/${id}`);
      } catch (error) {
        useErrorNotifications(error);
      }
      return response;
    }

    async function createOrderMinimumRule(payload: OrderMinimumManage) {
      let response: any;
      try {
        response = await useVaniloApi("/order-minimums", {
          method: "POST",
          body: {
            ...payload,
          },
        });
      } catch (error) {
        useErrorNotifications(error);
      }
      return response;
    }

    async function updateOrderMinimumRule(
      id: number,
      payload: OrderMinimumManage
    ) {
      let response: any;
      try {
        response = await useVaniloApi(`/order-minimums/${id}`, {
          method: "POST",
          body: {
            ...payload,
          },
        });
      } catch (error) {
        useErrorNotifications(error);
      }
      return response;
    }

    return {
      orderMinimumRules,
      getOrderMinimumRules,
      deleteOrderMinimumRule,
      getOrderMinimumRulesStore,
      editingOrderMinimumRule,
      viewingOrderMinimumRule,
      getSingleOrderMinimumRule,
      createOrderMinimumRule,
      updateOrderMinimumRule,
    };
  }
);
