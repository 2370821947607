import { defineStore } from "pinia";

import {
  OrderExpressServiceManage,
  OrderExpressService,
} from "~/types/settings-order-express-services";

export const useSettingsOrderExpressServicesStore = defineStore(
  "settings-order-express-services",
  () => {
    // Express Services
    const expressServices = ref<OrderExpressService[]>([]);

    async function getOrderExpressServiceList() {
      let response: any;
      try {
        response = await useVaniloApi("/order-express-services");
      } catch (error) {
        useErrorNotifications(error);
      }
      expressServices.value = response?.data;
      return response;
    }

    // Store Order Express Services
    async function getStoreOrderExpressServiceList(id: any) {
      let response: any;
      try {
        response = await useVaniloApi(`/order-express-services/store/${id}`);
      } catch (error) {
        useErrorNotifications(error);
      }
      return response;
    }

    // Editing Express Service
    const editingExpressService = ref<OrderExpressServiceManage>(
      {} as OrderExpressServiceManage
    );

    async function getOrderExpressService(id: any) {
      let response: any;
      try {
        response = await useVaniloApi(`/order-express-services/${id}`);
      } catch (error) {
        useErrorNotifications(error);
      }
      return response;
    }

    async function createOrderExpressService(payload: any) {
      let response: any;
      try {
        response = await useVaniloApi("/order-express-services", {
          method: "POST",
          body: {
            ...payload,
          },
        });
      } catch (error) {
        useErrorNotifications(error);
      }
      return response;
    }

    async function updateOrderExpressService(id: any, payload: any) {
      let response: any;
      try {
        response = await useVaniloApi(`/order-express-services/${id}`, {
          method: "POST",
          body: {
            ...payload,
          },
        });
      } catch (error) {
        useErrorNotifications(error);
      }
      return response;
    }

    async function deleteOrderExpressService(id: any) {
      let response: any;
      try {
        response = await useVaniloApi(`/order-express-services/${id}`, {
          method: "DELETE",
        });
      } catch (error) {
        useErrorNotifications(error);
      }
      return response;
    }

    // Viewing Express Service
    const viewingExpressService = ref<OrderExpressService>(
      {} as OrderExpressService
    );

    return {
      expressServices,
      getOrderExpressServiceList,
      getStoreOrderExpressServiceList,
      editingExpressService,
      getOrderExpressService,
      createOrderExpressService,
      updateOrderExpressService,
      deleteOrderExpressService,
      viewingExpressService,
    };
  }
);
