import { defineStore } from "pinia";
import {
  LeadRuleManage,
  LeadRule,
  LeadTimeType,
} from "~/types/settings-lead-times";

export const useSettingsLeadTimesStore = defineStore(
  "settings-lead-times",
  () => {
    // Lead Times Rules
    const leadTimesRules = ref<LeadRule[]>([]);

    async function getLeadTimesRules() {
      let response: any;
      try {
        response = await useVaniloApi("/lead-times");
      } catch (error) {
        useErrorNotifications(error);
      }
      leadTimesRules.value = response?.data;
      return response;
    }

    async function getLeadTimesRulesStore(id) {
      let response: any;
      try {
        response = await useVaniloApi(`/lead-times/store/${id}`);
      } catch (error) {
        useErrorNotifications(error);
      }
      return response;
    }

    async function getSingleLeadTimesRule(id) {
      let response: any;
      try {
        response = await useVaniloApi(`/lead-times/${id}`);
      } catch (error) {
        useErrorNotifications(error);
      }
      return response;
    }

    async function createLeadTimesRule(payload) {
      let response: any;
      try {
        response = await useVaniloApi("/lead-times", {
          method: "POST",
          body: {
            ...payload,
          },
        });
      } catch (error) {
        useErrorNotifications(error);
      }
      return response;
    }

    async function updateLeadTimesRule(id, payload) {
      let response: any;
      try {
        response = await useVaniloApi(`/lead-times/${id}`, {
          method: "POST",
          body: {
            ...payload,
          },
        });
      } catch (error) {
        useErrorNotifications(error);
      }
      return response;
    }

    async function deleteLeadTimesRule(id) {
      let response: any;
      try {
        response = await useVaniloApi(`/lead-times/${id}`, {
          method: "DELETE",
        });
      } catch (error) {
        useErrorNotifications(error);
      }
      return response;
    }

    // Editing Lead Times Rule
    const editingLeadTimesRule = ref<LeadRuleManage>({} as LeadRuleManage);

    // Viewing Lead Times Rule
    const viewingLeadTimesRule = ref<LeadRule>({} as LeadRule);

    // Type Options
    const typeOptions = ref([
      {
        name: "Preparation Time",
        value: LeadTimeType.PREPARATION,
      },
      {
        name: "Fixed Time",
        value: LeadTimeType.FIXED,
      },
      {
        name: "Next Available Day",
        value: LeadTimeType.NEXT_DAY,
      },
      {
        name: "Required Make Time",
        value: LeadTimeType.REQUIRED,
      },
    ]);

    return {
      // Lead Times Rules
      leadTimesRules,
      getLeadTimesRules,
      getLeadTimesRulesStore,
      getSingleLeadTimesRule,
      createLeadTimesRule,
      updateLeadTimesRule,
      deleteLeadTimesRule,

      // Editing Lead Times Rule
      editingLeadTimesRule,

      // Viewing Lead Times Rule
      viewingLeadTimesRule,

      // Type Options
      typeOptions,
    };
  }
);
