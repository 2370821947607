export interface LeadRuleManage {
  name: string;
  is_active: boolean;
  type: string;
  message: string;
  hours: number;
  minutes: number;
  store_id?: number;
  order_types: {
    type: string;
    is_exclusion: boolean;
  }[];
  tags: {
    id: number;
    is_exclusion: boolean;
  }[];
  categories: {
    id: number;
    is_exclusion: boolean;
  }[];
  channels: {
    id: number;
    is_exclusion: boolean;
  }[];
  attributes: {
    id: number;
    is_exclusion: boolean;
  }[];
}
export interface LeadRule {
  id: number;
  name: string;
  is_active: boolean;
  type: string;
  message: string;
  store_id?: number;
  hours: number;
  minutes: number;
  order_types: {
    type: string;
    is_exclusion: boolean;
  }[];
  tags: {
    id: number;
    is_exclusion: boolean;
  }[];
  categories: {
    id: number;
    is_exclusion: boolean;
  }[];
  channels: {
    id: number;
    is_exclusion: boolean;
  }[];
  attributes: {
    id: number;
    is_exclusion: boolean;
  }[];
}
export enum LeadTimeType {
  PREPARATION = "preparation",
  NEXT_DAY = "next-day",
  FIXED = "fixed",
  REQUIRED = "required",
}
